import links from "../../helper/links"
import { IItems } from "../../interfaces/IMenuItems"

// what we do items
export const whatWeDoItems : IItems[] = [
  {name: 'Fleet Transition', event: 'fleetTransition', href: links.fleet_transition},
  {name: 'Charging Solutions', event: 'chargingSolutions', href: links.charging_solutions},
  {name: 'Energy Management', event: 'energyManagement', href: links.energy_management},
  {name: 'E-Mobility Education', event: 'emobilityEducation', href: links.emobility_education}
]

export const qaWhatWeDoItems : IItems[] = []
// end of what we do items


// learn items
export const learnItems: IItems[] = [
  {name: 'Insights', event: 'insights', href: links.insights},
  // {name: 'Case Studies', event: 'caseStudies', href: links.case_studies},
]

export const qaLearnItems : IItems[] = []
// end of learn items


// follow items
export const followItems: IItems[] = [
  {name: 'LinkedIn', event: 'linkedin', href: links.linkedin, aRef: true},
]

export const qaFollowItems : IItems[] = []
// end of learn items

// company items
export const companyItems: IItems[] = [
  {name: 'About Us', event: 'aboutUs', href: links.about_us},
  {name: 'Contact Us', event: 'contactUs', href: links.contact_us},
  {name: 'Terms', event: 'terms', href: links.nevo_terms, aRef: true},
  {name: 'Privacy', event: 'privacy', href: links.nevo_privacy, aRef: true},
  {name: 'Cookies', event: 'cookies', href: links.nevo_cookies, aRef: true},
]
export const qaCompanyItems : IItems[] = []
// end of company items