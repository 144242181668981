
const links = {
  home: '/',

  fleet_transition: '/fleet-transition',
  charging_solutions: '/charging-solutions',
  energy_management: '/energy-management',
  emobility_education: '/emobility-education',
  
  telematics: '/telematics',
  energy: '/energy',

  insights: '/insights',
  case_studies: '/case-studies',

  about_us: '/about-us',
  contact_us: '/contact-us',

  home_charging: '/home-charging',
  commercial_charging: '/commercial-charging',
  consultancy: '/consultancy',

  // nevo links
  nevo_web: 'https://nevo.ie?campaign_id=NevoAdvisory',
  nevo_vehicles: 'https://www.nevo.ie/commercial-vehicles/all?campaign_id=NevoAdvisory',
  nevo_terms: 'https://nevo.ie/terms?campaign_id=NevoAdvisory',
  nevo_privacy: 'https://nevo.ie/privacy?campaign_id=NevoAdvisory',
  nevo_cookies: 'https://nevo.ie/cookies?campaign_id=NevoAdvisory',

  drive_inc: 'https://www.driveinc.ie',
  tradebid: 'https://trade-bid.ie',
  sweep: 'https://www.sweep.ie',
  instabid: 'https://instabid.ie',

  geotab_link: 'https://www.geotab.com/request-demo',
  esb_link: 'https://esb.ie/what-we-do/smart-energy-services/request-a-callback',

  linkedin: 'https://www.linkedin.com/showcase/nevo-charging/',

  esb_youtube_video: 'https://www.youtube.com/watch?v=BdBvKV1POTc',
}

export default links