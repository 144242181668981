import React from 'react';
import Link from 'next/link';

import eventGTM from "../lib/gtag";

import { IItems } from '../interfaces/IMenuItems';

interface IMenuItems {
  items: IItems[];
  eventType: string;
  hideMenu?: () => void;
}

export default function MenuItems({ items, eventType, hideMenu }: IMenuItems) {
  return <>
    {items.map(item => (
      <p 
        key={item.event}
        onClick={() => {
          eventGTM(item.event + eventType)
          if (hideMenu) hideMenu()
        }}
      >
        { item.aRef ? (
          <a href={item.href} target='_blank' rel='noreferrer'>{item.name}</a>
        ) : (
          <Link href={item.href}>{item.name}</Link> 
        )}
      </p>
    ))}
  </>
}