import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router';

import MenuItems from '../../utils/RenderMenuItems';
import IMenuItems from '../../interfaces/IMenuItems';

import isQAorDev from '../../utils/DevelopmentMode';

interface SubMenuItemsType {
  0: JSX.Element;
  1: number;
}

const SubMenuItems = ({ items, qaItems, hideMenu, eventType }: IMenuItems) => {
  const [allItems, setAllItems] = useState(items)

  const router = useRouter()

  useEffect(() => {
    if (router.isReady) {

      if (isQAorDev() && qaItems.length > 0) {  
      
        // if there is no QA item -> add all of them
        const qaItem = allItems.find((item: any) => item.name === qaItems[0].name)
        if (!qaItem) {
          setAllItems(allItems.concat(qaItems))
        }
      }

    }
  }, [router, allItems, qaItems, eventType])

  const component: SubMenuItemsType = [
    <MenuItems key={items[0].name} items={allItems} eventType={eventType} hideMenu={hideMenu} />,
    allItems.length - 1
  ]

  return component
};

export default SubMenuItems;