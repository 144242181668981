import React from "react"

export default function NevoLogo({ color = '#fff' }) {
  return (
    <svg id="Group_988" data-name="Group 988" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="265.793" height="97.178" viewBox="0 0 265.793 97.178">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_2324" data-name="Rectangle 2324" width="265.793" height="97.178" fill={color}/>
        </clipPath>
      </defs>
      <g id="Group_988-2" data-name="Group 988" clipPath="url(#clip-path)">
        <path id="Path_3705" data-name="Path 3705" d="M37.288,37.588,9.722.033,0,.051l37.282,51.12.006,0v0H40.95a4.233,4.233,0,0,0,4.233-4.233V0H37.288Z" transform="translate(0 0.001)" fill={color}/>
        <path id="Path_3706" data-name="Path 3706" d="M0,74.742H7.895V57.447L0,46.6Z" transform="translate(0 -23.57)" fill={color}/>
        <path id="Path_3707" data-name="Path 3707" d="M187.5.067H155.753A4.233,4.233,0,0,0,151.52,4.3V8.078h40.211V4.3A4.233,4.233,0,0,0,187.5.067" transform="translate(-76.639 -0.034)" fill={color}/>
        <path id="Path_3708" data-name="Path 3708" d="M151.521,91.112a4.233,4.233,0,0,0,4.233,4.233H187.5a4.233,4.233,0,0,0,4.232-4.233V87.333h-40.21Z" transform="translate(-76.64 -44.173)" fill={color}/>
        <rect id="Rectangle_2322" data-name="Rectangle 2322" width="40.21" height="8.011" transform="translate(74.881 21.596)" fill={color}/>
        <path id="Path_3709" data-name="Path 3709" d="M294.235,0H288.6L308.02,48.481a4.233,4.233,0,0,0,3.906,2.658l5.658.031L298.165,2.659A4.232,4.232,0,0,0,294.235,0" transform="translate(-145.972 0.001)" fill={color}/>
        <path id="Path_3710" data-name="Path 3710" d="M353.445,22.276,357.7,32.891,370.914,0h-8.505Z" transform="translate(-178.773 0)" fill={color}/>
        <path id="Path_3711" data-name="Path 3711" d="M465.332.073V8.549a17.974,17.974,0,1,1-13.552,0V.073a26.016,26.016,0,1,0,13.552,0" transform="translate(-218.78 -0.037)" fill={color}/>
        <path id="Path_3712" data-name="Path 3712" d="M13.874,169.911H4.625l-1.7,4.716H0l7.667-21.084h3.195L18.5,174.626H15.578Zm-.791-2.252-3.834-10.71-3.833,10.71Z" transform="translate(0 -77.662)" fill={color}/>
        <path id="Path_3713" data-name="Path 3713" d="M91.291,154.589a9.1,9.1,0,0,1,3.895,3.7,12.475,12.475,0,0,1,0,11.3,8.934,8.934,0,0,1-3.895,3.651,13.359,13.359,0,0,1-6.009,1.262h-6.6V153.3h6.6a13.1,13.1,0,0,1,6.009,1.293m.259,15.456a8.283,8.283,0,0,0,2.16-6.1,8.453,8.453,0,0,0-2.175-6.176,8.361,8.361,0,0,0-6.253-2.221H81.448V172.22h3.834a8.453,8.453,0,0,0,6.268-2.175" transform="translate(-39.796 -77.537)" fill={color}/>
        <path id="Path_3714" data-name="Path 3714" d="M172.494,153.3l-8,21.207H161.3l-8-21.207h2.951l6.663,18.285,6.663-18.285Z" transform="translate(-77.537 -77.537)" fill={color}/>
        <rect id="Rectangle_2323" data-name="Rectangle 2323" width="2.769" height="21.207" transform="translate(115.038 75.758)" fill={color}/>
        <path id="Path_3715" data-name="Path 3715" d="M284.741,173.689a6.254,6.254,0,0,1-2.6-2.069,5.294,5.294,0,0,1-.973-3.058h2.951a3.885,3.885,0,0,0,1.232,2.51,4.419,4.419,0,0,0,3.15,1.02,4.6,4.6,0,0,0,3.118-.989,3.208,3.208,0,0,0,1.141-2.541,2.888,2.888,0,0,0-.669-1.978,4.289,4.289,0,0,0-1.673-1.156,26.251,26.251,0,0,0-2.708-.852,23.761,23.761,0,0,1-3.362-1.1,5.41,5.41,0,0,1-2.16-1.719,5.049,5.049,0,0,1-.9-3.149,5.459,5.459,0,0,1,.882-3.073,5.751,5.751,0,0,1,2.48-2.069,8.728,8.728,0,0,1,3.666-.73,7.68,7.68,0,0,1,4.883,1.491,5.509,5.509,0,0,1,2.145,3.955H292.3a3.287,3.287,0,0,0-1.278-2.145,4.541,4.541,0,0,0-2.981-.928,4.323,4.323,0,0,0-2.83.9,3.065,3.065,0,0,0-1.1,2.51,2.72,2.72,0,0,0,.654,1.886,4.222,4.222,0,0,0,1.613,1.11q.959.38,2.693.867a25.341,25.341,0,0,1,3.377,1.141,5.647,5.647,0,0,1,2.191,1.734,5.045,5.045,0,0,1,.912,3.18,5.584,5.584,0,0,1-.821,2.921,6.041,6.041,0,0,1-2.434,2.221,8.033,8.033,0,0,1-3.8.852,9.068,9.068,0,0,1-3.758-.745" transform="translate(-142.214 -77.257)" fill={color}/>
        <path id="Path_3716" data-name="Path 3716" d="M356.18,173.081a10.133,10.133,0,0,1-3.849-3.864,11.722,11.722,0,0,1,0-11.166,10.14,10.14,0,0,1,3.849-3.864,11.227,11.227,0,0,1,10.8,0,10.057,10.057,0,0,1,3.834,3.849,11.9,11.9,0,0,1,0,11.2,10.054,10.054,0,0,1-3.834,3.849,11.227,11.227,0,0,1-10.8,0m9.386-2.054a7.279,7.279,0,0,0,2.8-2.951,10.219,10.219,0,0,0,0-8.9,7.337,7.337,0,0,0-2.784-2.936,8.309,8.309,0,0,0-8.032,0,7.331,7.331,0,0,0-2.784,2.936,10.219,10.219,0,0,0,0,8.9,7.276,7.276,0,0,0,2.8,2.951,8.254,8.254,0,0,0,8,0" transform="translate(-177.494 -77.288)" fill={color}/>
        <path id="Path_3717" data-name="Path 3717" d="M447.043,174.5l-5.05-8.671h-3.347V174.5h-2.768V153.3h6.846a9.086,9.086,0,0,1,4.062.821,5.723,5.723,0,0,1,2.479,2.221,6.148,6.148,0,0,1-4.229,9.28l5.325,8.884Zm-8.4-10.892h4.077a4.625,4.625,0,0,0,3.377-1.11,3.982,3.982,0,0,0,1.125-2.967,3.8,3.8,0,0,0-1.11-2.921,4.833,4.833,0,0,0-3.392-1.034h-4.077Z" transform="translate(-220.467 -77.537)" fill={color}/>
        <path id="Path_3718" data-name="Path 3718" d="M520.824,153.3l-6.906,13.2v8h-2.769v-8l-6.937-13.2h3.073l5.234,10.74,5.233-10.74Z" transform="translate(-255.031 -77.537)" fill={color}/>
      </g>
    </svg>
  )
}