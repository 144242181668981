/* eslint-disable @next/next/no-img-element */
import React, { useRef } from 'react';
import Link from 'next/link'

import useMobile from '../Hooks/Mobile';
import eventGTM from '../../lib/gtag';

import SideMenuNav from '../SideMenuNav';
import links from '../../helper/links';

import styles from './styles.module.scss';
import NevoLogo from '../../public/assets/images/nevo-advisory-logo';
import CloseIcon from '../../public/assets/icons/close.svg'

interface iSideMenu {
  hideMenu: any;
}

const SideMenu = ({ hideMenu }: iSideMenu) => {
  const mobile = useMobile(567);
  const refMenu = useRef<HTMLDivElement>(null)
  
  const onHideMenu = () => {
    if (refMenu && refMenu.current) {
      refMenu.current.classList.toggle(styles.sideMenuHide);
    }

    eventGTM('closeSideMenu')

    setTimeout(() => {
      if (refMenu && refMenu.current) {
        refMenu.current.style.display = "none";
      }

      const htmlDoc = document.children[0] as HTMLElement;   

      if (htmlDoc.style) {
        htmlDoc.style.overflow = "";
        document.body.style.overflow = "";
      }
      hideMenu();
    }, 475);
  }

  const renderSideMenuHeader = () => {
    return (
      <div className={styles.sideMenuHeader}>
        { 
          mobile && 
          <Link href='/' passHref>
            <div className={`${styles.nevoLogo} nevo-logo`}>
              <NevoLogo color="#1A1C1F" /> 
            </div>
          </Link>
        }
        
        <div onClick={onHideMenu} className={styles.closeIconContainer}>
          <img src={CloseIcon.src} alt="close icon" />
        </div>
        
      </div>
    )
  }
  
  return (
    <>
      <div ref={refMenu} className={styles.sideMenu}>
        <div className={styles.sideMenuContent}>
          
          { renderSideMenuHeader() }
          
          <div className={styles.menuContent}>
            <SideMenuNav hideMenu={onHideMenu} renderSideMenuHeader={renderSideMenuHeader} /> 
            
            <p className={`${styles.nevoChargingButton} nav-button`}>
              <a onClick={() => eventGTM("nevoSideMenu")} href={links.nevo_web} target='_blank' rel='noreferrer'>
                Nevo.ie
              </a>
            </p>

            <p className="nav-button" 
              onClick={() => {
                eventGTM("aboutUsSideMenu")
                onHideMenu();
              }}
            >
              <Link href={links.about_us}>About Us</Link>
            </p>

            <p className="nav-button" 
              onClick={() => {
                eventGTM("contactUsSideMenu")
                onHideMenu();
              }}
            >
              <Link href={links.contact_us}>Contact Us</Link>
            </p>
          </div>

          <div className={styles.bottomContent}>
            <p className="copyright-mark">© Nevo Advisory 2024</p>
          </div>

        </div>
      </div>
      
      <div onClick={onHideMenu} className={"fading-cover " + styles.fadingCover}>&nbsp;</div>
    </>
    
  )
}

export default SideMenu;