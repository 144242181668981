import { useEffect, useState } from "react";

const useMobile = (width = 1024) => {
  const [mobile, setMobile] = useState<boolean>();

  useEffect(() => {
    setMobile(window.innerWidth <= width);
  }, [width]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setMobile(window.innerWidth <= width);
      }

      window.addEventListener("resize", handleResize);
      
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [mobile, width]); 

  return mobile;
}

export default useMobile;