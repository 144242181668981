/* eslint-disable @next/next/no-img-element */

import React, { useState, useRef } from 'react';
import Link from 'next/link';
import SubMenuItems from '../SubMenuItems';

import eventGTM from '../../lib/gtag';

import { 
  whatWeDoItems, qaWhatWeDoItems,
  learnItems, qaLearnItems
} from '../SubMenuItems/menuItems'
import links from '../../helper/links';

import styles from './styles.module.scss'
import RightBlackArrow from '../../public/assets/icons/right-arrow-black.svg'

const SideMenuNav = ({ hideMenu, renderSideMenuHeader } : any) => {
  const [showWhat, setWhatItems] = useState(false);
  const [showLearn, setLearnItems] = useState(false);

  const refItems = useRef<HTMLDivElement>(null);

  const displayItems = showWhat || showLearn

  const [WhatWeDoItems]: any = SubMenuItems({ items: whatWeDoItems, qaItems: qaWhatWeDoItems, eventType: 'SideMenu' });
  const [LearnItems]: any = SubMenuItems({ items: learnItems, qaItems: qaLearnItems, eventType: 'SideMenu' });

  const hideItems = () => {
    if (refItems && refItems.current) {
      refItems.current.classList.toggle(styles.itemsMenuHide);
    }

    setTimeout(() => {
      if (refItems && refItems.current) {
        refItems.current.style.display = "none";
      }

      setWhatItems(false);
      setLearnItems(false);
    }, 475);
  }

  return (
    <>
      <div className={styles.sideMenuNav}>
        <div className="nav-button" onClick={() => {
          eventGTM('whatWeDoSideMenu')
          setWhatItems(true)
        }}>
          What we do <img src={RightBlackArrow.src} alt="right arrow"/>
        </div>

        <div className="nav-button" onClick={() => {
          eventGTM('telematicsSideMenu')
          hideMenu()
        }}>
          <Link href={links.telematics}>Telematics</Link>
        </div>

        <div className="nav-button" onClick={() => {
          eventGTM('energySideMenu')
          hideMenu()
        }}>
          <Link href={links.energy}>Energy</Link>
        </div>

        {/* <div className="nav-button" onClick={() => {
          eventGTM('learnSideMenu')
          setLearnItems(true)
        }}>
          Learn <img src={RightBlackArrow.src} alt="right arrow"/>
        </div> */}
      </div>

      { displayItems &&
        <div className={styles.itemsMenu} ref={refItems}>
          <div className={styles.itemsHolder}>
            { renderSideMenuHeader() }

            <div className={styles.goBackHolder} onClick={() => {
              eventGTM('goBackSideMenu')
              hideItems()
            }}>
              <img src={RightBlackArrow.src} alt="right arrow"/>
            </div>
            
            { showWhat && 
              <>
                <p className={`${styles.leadingNavButton} default-cursor`}>
                  What we do
                </p>
                
                { WhatWeDoItems }
              </> 
            }
            { showLearn && 
              <>
                <p className={`${styles.leadingNavButton} default-cursor`}>
                  Learn
                </p>
                
                { LearnItems }
              </> 
            }
          </div>
        </div>
      }
    </>
  )
};

export default SideMenuNav;